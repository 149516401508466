import { BRAND_DOMAIN } from '../../constants/Branding';

export const DevUrlsMap = {
  REACT_APP_REST_API_URL: `https://stage-api.quickexch.com/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://stage-api.quickexch.com`,
  REACT_APP_REST_REPORTING_API_URL: `https://stage-reporting.quickexch.com`,
  // REACT_APP_WEBSOCKET_URL_BETFAIR: `https://stage-feed.mysportsfeed.io/odds-feed`,
  // REACT_APP_WEBSOCKET_URL_DREAM: `https://stage-feed.mysportsfeed.io/odds-feed`, //BM&Fancy
  // REACT_APP_WEBSOCKET_URL_SP: `https://stage-feed.mysportsfeed.io/odds-feed`, //BM&Fancy
  // BINARY_WEBSOCKET_URL: `https://stage-api.${BRAND_DOMAIN}/hypex-ws`,
  REACT_APP_WEBSOCKET_URL_BETFAIR: `https://feed.mysportsfeed.io/odds-feed`,
  REACT_APP_WEBSOCKET_URL_DREAM: `https://feed.mysportsfeed.io/odds-feed`, //BM&Fancy
  REACT_APP_WEBSOCKET_URL_SP: `https://feed.mysportsfeed.io/odds-feed`, //BM&Fancy
  BINARY_WEBSOCKET_URL: `https://feed.mysportsfeed.io/hypex-ws`,
  REACT_APP_REST_AGPAY_URL: `https://dev-agpay.quickexch.com`,
  WS_TOPIC_PREFIX: ``,
};

export const ProdUrls = {
  REACT_APP_REST_API_URL: `https://api.${BRAND_DOMAIN}/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://api.${BRAND_DOMAIN}`,
  REACT_APP_REST_REPORTING_API_URL: `https://reporting.${BRAND_DOMAIN}`,
  REACT_APP_WEBSOCKET_URL_BETFAIR: `https://feed.mysportsfeed.io/odds-feed`,
  REACT_APP_WEBSOCKET_URL_DREAM: `https://feed.mysportsfeed.io/odds-feed`, //BM&Fancy
  REACT_APP_WEBSOCKET_URL_SP: `https://feed.mysportsfeed.io/odds-feed`, //BM&Fancy
  BINARY_WEBSOCKET_URL: `https://feed.mysportsfeed.io/hypex-ws`,
  REACT_APP_REST_AGPAY_URL: `https://agpay.${BRAND_DOMAIN}`,
  WS_TOPIC_PREFIX: ``,
};

export const EnvUrlsMap = {
  development: DevUrlsMap,
  stage: DevUrlsMap,
  production: ProdUrls,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}

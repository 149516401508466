import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { RootState } from '../../models/RootState';
import HomeView from '../../constants/HomeView';
import Footer from '../Footer/Footer';
import './Home.scss';
import { useTheme } from '@material-ui/core';
// import Banner from '../../assets/images/common/Banner.png';
import CardView from './CardView';
import { IonRow, IonCol, IonSelect, IonSelectOption } from '@ionic/react';
import { Tabs } from '@material-ui/core';
import TabPanel from '../../components/TabPanel/TabPanel';
import {
  FatchLiveCasinoGames,
  handleSearchGame,
  setSearchGame,
} from '../../store';
import { GapGame } from '../../models/dc/DcGame';
import {
  CASINO_MOB_NAV_PROVIDERS,
  CASINO_WEB_NAV_PROVIDERS,
  CASINO_WEB_NAV_PROVIDERS1,
  CASINO_WEB_NAV_PROVIDERS2,
  EZUGI_PROVIDERS,
  LIVE_WEB_CASINO,
  VIRTUAL_CASINO,
} from '../../constants/CasinoView';
import NotificationPage from './Notification';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import SlotView from './SlotView';
import SearchContainer from '../SearchContainer/SearchContainer';
import Search from '../../assets/images/home/homeview/search.svg';
import { filter } from 'ionicons/icons';
import { useLocation, useParams } from 'react-router';
import NotificationTab from '../Notification-tab/NotificationTab';
import Copyright from '../Copyright/Copyright';
import { BRAND_NAME } from '../../constants/Branding';
import { EzugiChanges } from '../../constants/WhitelabelConfig';
import { AuthResponse } from '../../models/api/AuthResponse';
import SVLS_API from '../../svls-api';

const WebBanners = HomeView.HOME_PAGE_WEB_BANNERS;
const tabsList = HomeView.CARDS_SLIDER_IMAGES;

type StoreProps = {
  loggedIn: boolean;
  allowedConfig: number;
  FatchLiveCasinoGames: (type: string) => void;
  liveCasinoGames: GapGame[];
  setSearchGame: Function;
  searchGame: string;
  handleSearchGame: () => void;
};

const Home: React.FC<StoreProps> = (props) => {
  const {
    allowedConfig,
    FatchLiveCasinoGames,
    liveCasinoGames,
    setSearchGame,
    searchGame,
    handleSearchGame,
  } = props;
  const [casinoGameTabValue, setCasinoGameCatTabValue] = useState<number>(0);
  const [filteredProvider, setFilteredProvider] = useState<string>('');
  const [filteredAllProvider, setAllProvider] = useState<string>('');
  const [filteredPopularProvider, setPopularProvider] = useState<string>('');
  const [providerList, setProviderList] = useState<string[]>();
  const [filteredGames, setFilteredGames] = useState([]);
  const [liveGames, setLiveGames] = useState([]);
  const popularProviders = [
    'EZUGI',
    'Evolution Gaming',
    'Virtual Sports',
    'Royal Gaming',
  ];

  let location = useLocation();
  const [gamefilter, setGameFilter] = useState('');
  const [apiWebBanners, setApiWebBanners] = useState([]);
  const [apiMobBanners, setApiMobBanners] = useState([]);

  useEffect(() => {
    setGameFilter(location.pathname.split('/home/')[1]);
  }, [location]);

  useEffect(() => {
    FatchLiveCasinoGames('live-casino');
    fetchBannerData();
  }, []);

  useEffect(() => {
    if (filteredAllProvider) {
      setPopularProvider('');
      setFilteredProvider(filteredAllProvider);
    }
  }, [filteredAllProvider]);

  useEffect(() => {
    if (filteredPopularProvider) {
      setAllProvider('');
      setFilteredProvider(filteredPopularProvider);
    }
  }, [filteredPopularProvider]);

  const getBanners = () => {
    return WebBanners.filter((m) => (m.configPermission & allowedConfig) !== 0);
  };

  const settings = {
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,
  };

  const theme = useTheme();
  let royalgames = liveCasinoGames.filter((g) => {
    return g.providerName === 'RG';
  });
  let slider;
  const next = () => {
    slider.slickNext();
  };
  const previous = () => {
    slider.slickPrev();
  };

  useEffect(() => {
    if (liveCasinoGames?.length > 0)
      setProviderList(
        liveCasinoGames
          .map((g) => g.subProviderName)
          .filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
      );
  }, [liveCasinoGames]);

  useEffect(() => {
    if (liveCasinoGames?.length > 0) {
      setLiveGames(
        liveCasinoGames.filter((i) => i.category.toLowerCase().includes('live'))
      );
    }
  }, [liveCasinoGames]);

  useEffect(() => {
    if (liveCasinoGames?.length > 0) {
      setFilteredGames(
        liveCasinoGames.filter((i) => filteredProvider == i.subProviderName)
      );
    }
  }, [filteredProvider]);

  const fetchBannerData = async () => {
    let hostname: string[] = window.location.hostname.split('.');
    let DOMAIN_URL = hostname[hostname?.length - 2] + '.' + hostname[hostname.length - 1];
    //let DOMAIN_URL = 'hypexexch.com'
    let webdata = [];
    let mobiledata = [];
    try {
      const response: AuthResponse = await SVLS_API.get(
        `/account/v2/books/${DOMAIN_URL}/banners`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            status: 'active',
            type: '*',
            category:'homebanner'
          },
        }
      );
      let data = response?.data?.banners;
      if(data?.length > 0){
      data.map(item=> {
        if(item.deviceType === "desktop"){
          webdata.push(item);
        }else if(item.deviceType === "mobile"){
          mobiledata.push(item)
        }
      })
      setApiWebBanners(webdata);
      setApiMobBanners(mobiledata);
    }
    if(BRAND_NAME.toLocaleLowerCase() === "chal247"){
      if(webdata.length == 0) {
        setApiWebBanners(EZUGI_PROVIDERS);
      } 
      if(mobiledata.length == 0){
        setApiMobBanners(EZUGI_PROVIDERS);
      }
    }else{
      if(webdata.length == 0) {
        setApiWebBanners(CASINO_WEB_NAV_PROVIDERS);
      } 
      if(mobiledata.length == 0){
        setApiMobBanners(CASINO_MOB_NAV_PROVIDERS);
      }
    }
    } catch (err) {
      if(BRAND_NAME.toLocaleLowerCase() === "chal247"){
        if(webdata.length == 0) {
          setApiWebBanners(EZUGI_PROVIDERS);
        } 
        if(mobiledata.length == 0){
          setApiMobBanners(EZUGI_PROVIDERS);
        }
      }else{
        if(webdata.length == 0) {
          setApiWebBanners(CASINO_WEB_NAV_PROVIDERS);
        } 
        if(mobiledata.length == 0){
          setApiMobBanners(CASINO_MOB_NAV_PROVIDERS);
        }
      }
      console.log(err)
    }
    
  };
  return (
    <>
      <div className="home-page-ctn">
        <div className="page-banner-ctn">
          {
            BRAND_NAME.toLocaleLowerCase() === "chal247" ? (
              <Slider {...settings}>
                {apiWebBanners?.length > 0 &&
                  apiWebBanners.map((g, i) => {
                    return (
                      <>
                        <div className="slider-images">
                          <img
                            className="banner-images"
                            src={g?.publicUrl}
                            key={'g?.publicUrl' + i}
                            alt={g?.title}
                          />
                        </div>
                      </>
                    );
                  })}
              </Slider>
            ) : (
              <>
                <Slider {...settings} className="web-view">
                  {apiWebBanners?.length > 0 &&
                    apiWebBanners.map((g, i) => {
                      return (
                        <>
                          <div className="slider-images">
                            <img
                              className="banner-images"
                              src={g?.publicUrl}
                              key={'g?.publicUrl' + i}
                              alt={g?.title}
                            />
                          </div>
                        </>
                      );
                    })}
                </Slider>
                <Slider {...settings} className="mob-view">
                  {apiMobBanners?.length > 0 &&
                    apiMobBanners.map((g, i) => {
                      return (
                        <>
                          <div className="slider-images">
                            <img
                              className="banner-images"
                              src={g?.publicUrl}
                              key={'g?.publicUrl' + i}
                              alt={g?.title}
                            />
                          </div>
                        </>
                      );
                    })}
                </Slider>
              </>
            )
          }

          {/* <SearchContainer /> */}

          <NotificationTab />

          {/* <IonRow className="banners-row">
            <Carousel
              className="home-banners"
              autoPlay={true}
              infiniteLoop={true}
              interval={5000}
              showThumbs={false}
              showStatus={false}
            >
              {getBanners().map((banner) => (
                <div key={banner.text + 'container'}>
                  <img
                    className="banner-images"
                    src={banner.image}
                    key={banner.text + 'image'}
                    alt={'banner'}
                  />
                </div>
              ))}
            </Carousel>
          </IonRow> */}
        </div>
        <div className="home-content">
          {/* <img src={Background} className="background-img" /> */}
          {/* <SlotView liveCasinoGames={liveCasinoGames} /> */}
          {/* <IonRow className="banners-row">
            <img
              className="banner-images"
              src={Banner}
              key={'image'}
              alt={'banner'}
            />
          </IonRow> */}
          {
            <TabPanel value={casinoGameTabValue} index={0}>
              <CardView
                liveCasinoGames={
                  gamefilter
                    ? liveCasinoGames
                      ?.filter((i) =>
                        i.gameName.toLowerCase().includes(gamefilter)
                      )
                      ?.slice(0, 6)
                    : liveCasinoGames?.slice(0, 6)
                }
                heading="Trending Now"
              />{' '}
              <CardView
                liveCasinoGames={
                  filteredProvider
                    ? filteredGames
                    : gamefilter
                      ? liveCasinoGames
                        ?.filter((i) =>
                          i.gameName.toLowerCase().includes(gamefilter)
                        )
                        ?.slice(2, 14)
                      : liveCasinoGames?.slice(2, 14)
                }
                heading="Most Popular Games"
              />{' '}
              {!EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? (
                <CardView
                  liveCasinoGames={
                    filteredProvider
                      ? filteredGames
                      : gamefilter
                        ? liveCasinoGames
                          .filter((g) => g.category.toLowerCase() == 'virtual')
                          ?.filter((i) =>
                            i.gameName.toLowerCase().includes(gamefilter)
                          )
                          ?.slice(0, 6)
                        : liveCasinoGames
                          .filter((g) => g.category.toLowerCase() == 'virtual')
                          ?.slice(0, 6)
                  }
                  heading="Recently Played"
                />
              ) : null}
            </TabPanel>
          }

          <TabPanel value={casinoGameTabValue} index={1}>
            <CardView
              liveCasinoGames={
                filteredProvider
                  ? filteredGames
                  : gamefilter
                    ? liveCasinoGames?.filter((i) =>
                      i.gameName.toLowerCase().includes(gamefilter)
                    )
                    : liveCasinoGames
              }
              heading="Roulette"
            />{' '}
          </TabPanel>
          <TabPanel value={casinoGameTabValue} index={2}>
            <CardView
              liveCasinoGames={
                filteredProvider
                  ? filteredGames
                  : gamefilter
                    ? liveCasinoGames?.filter((i) =>
                      i.gameName.toLowerCase().includes(gamefilter)
                    )
                    : liveCasinoGames
              }
              heading="Baccarat
"
            />{' '}
          </TabPanel>
          <TabPanel value={casinoGameTabValue} index={3}>
            <CardView
              liveCasinoGames={
                filteredProvider
                  ? filteredGames
                  : gamefilter
                    ? liveCasinoGames?.filter((i) =>
                      i.gameName.toLowerCase().includes(gamefilter)
                    )
                    : liveCasinoGames
              }
              heading="Casino"
            />{' '}
          </TabPanel>
          <TabPanel value={casinoGameTabValue} index={4}>
            <CardView
              liveCasinoGames={
                filteredProvider
                  ? filteredGames
                  : gamefilter
                    ? liveCasinoGames?.filter((i) =>
                      i.gameName.toLowerCase().includes(gamefilter)
                    )
                    : liveCasinoGames
              }
              heading="Casino"
            />{' '}
          </TabPanel>
        </div>
        <Copyright />
        {/* <Footer></Footer> */}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
    allowedConfig: state.common.allowedConfig,
    liveCasinoGames: state.indianCasino.liveCasinoGames,
    searchGame: state.indianCasino.searchGame,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    FatchLiveCasinoGames: (type: string) =>
      dispatch(FatchLiveCasinoGames(type)),
    setSearchGame: (data: string) => dispatch(setSearchGame(data)),
    handleSearchGame: () => dispatch(handleSearchGame()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
